import type { UrlObject } from "url";
import { isEmpty } from "lodash-es";
import type { LinkProps } from "./Link";
import { Link } from "./Link";
import type { LinkProps as _NextLinkProps } from "next/link";
import type { ForwardRefExoticComponent } from "react";
import { forwardRef } from "react";

declare type Url = string | UrlObject;

export type NextLinkProps = Omit<_NextLinkProps, "href"> &
    Omit<LinkProps, "as" | "href"> & {
        href: Url;
        innerLink?: ForwardRefExoticComponent<any>;
    };

const NextLink = forwardRef<HTMLAnchorElement, NextLinkProps>(
    (
        {
            innerLink: InnerLink = Link,
            href,
            as,
            replace,
            scroll,
            shallow,
            prefetch = false,
            children,
            ...chakraProps
        },
        ref
    ) => {
        if (!isEmpty(href)) {
            return (
                <InnerLink
                    ref={ref}
                    sx={{ _hover: { color: "gray.500" } }}
                    {...chakraProps}
                    passHref={true}
                    href={href}
                    replace={replace}
                    scroll={scroll}
                    shallow={shallow}
                    prefetch={prefetch}
                    style={{ textDecoration: "none" }}
                >
                    {children}
                </InnerLink>
            );
        }
        return (
            <InnerLink href="" {...chakraProps}>
                {children}
            </InnerLink>
        );
    }
);

NextLink.displayName = "NextLink";

export { NextLink };
