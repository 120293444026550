import { forwardRef, chakra } from "@chakra-ui/react";
import type { FlexProps } from "../../layout/flex/Flex";
import { Flex } from "../../layout/flex/Flex";

export interface FallbackImageProps extends FlexProps {
    alt: string;
}

export const FallbackImage = forwardRef<FallbackImageProps, "svg">(
    ({ css, fill, ...props }, ref) => (
        <Flex justifyContent="center" alignItems="center" width="100%" height="100%" {...props}>
            <chakra.svg
                width="50%"
                height="50%"
                maxH={"48px"}
                maxW={"48px"}
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
                fill={fill}
                ref={ref}
                role="img"
            >
                <path
                    d="M38 46H9.99997C7.87824 46 5.84343 45.1572 4.34314 43.6569C2.84285 42.1566 2 40.1217 2 38V10C2 7.87827 2.84285 5.84343 4.34314 4.34314C5.84343 2.84285 7.87824 2 9.99997 2H38C39.0648 2.00317 40.1184 2.2173 41.1 2.63C41.4673 2.78516 41.758 3.07986 41.908 3.44931C42.058 3.81876 42.0551 4.23268 41.9 4.60001C41.7448 4.96733 41.4501 5.25796 41.0807 5.40799C40.7112 5.55802 40.2973 5.55515 39.93 5.39999C39.3204 5.13731 38.6638 5.00123 38 5H9.99997C8.67389 5 7.40213 5.52677 6.46445 6.46445C5.52677 7.40213 5 8.67392 5 10V38C5 39.3261 5.52677 40.5978 6.46445 41.5355C7.40213 42.4732 8.67389 43 9.99997 43H38C39.3261 43 40.5979 42.4732 41.5356 41.5355C42.4732 40.5978 43 39.3261 43 38V10C43 9.60218 43.1581 9.22064 43.4394 8.93933C43.7207 8.65803 44.1021 8.5 44.5 8.5C44.8978 8.5 45.2793 8.65803 45.5606 8.93933C45.8419 9.22064 46 9.60218 46 10V38C46 40.1217 45.1571 42.1566 43.6568 43.6569C42.1565 45.1572 40.1217 46 38 46V46Z"
                    fill="gray.200"
                />
                <path
                    d="M17.8 34.3201C17.0773 34.3225 16.3613 34.1814 15.6936 33.905C15.0258 33.6287 14.4196 33.2225 13.91 32.7101L11.58 30.3801C11.1114 29.9129 10.4767 29.6506 9.81497 29.6506C9.15329 29.6506 8.51863 29.9129 8.05002 30.3801L4.53 33.9L2.41 31.78L5.93002 28.2601C6.96114 27.2311 8.35828 26.6532 9.81497 26.6532C11.2717 26.6532 12.6689 27.2311 13.7 28.2601L16.03 30.59C16.5076 31.0437 17.1413 31.2968 17.8 31.2968C18.4588 31.2968 19.0924 31.0437 19.57 30.59L28.13 22.0201C28.6407 21.5091 29.2472 21.1037 29.9146 20.8271C30.5821 20.5506 31.2975 20.4082 32.02 20.4082C32.7425 20.4082 33.4579 20.5506 34.1253 20.8271C34.7928 21.1037 35.3992 21.5091 35.91 22.0201L45.66 31.78L43.54 33.9L33.79 24.15C33.3147 23.6917 32.6802 23.4357 32.02 23.4357C31.3598 23.4357 30.7252 23.6917 30.25 24.15L21.69 32.6801C21.1829 33.1979 20.5778 33.6097 19.9099 33.8912C19.242 34.1728 18.5248 34.3186 17.8 34.3201V34.3201Z"
                    fill="gray.200"
                />
                <path
                    d="M14.5 20.5C13.3133 20.5 12.1533 20.1481 11.1666 19.4888C10.1799 18.8295 9.41082 17.8924 8.95669 16.7961C8.50257 15.6997 8.38375 14.4933 8.61526 13.3294C8.84677 12.1655 9.41824 11.0965 10.2574 10.2574C11.0965 9.41824 12.1655 8.8468 13.3294 8.61529C14.4933 8.38378 15.6998 8.5026 16.7961 8.95672C17.8925 9.41085 18.8295 10.1799 19.4888 11.1666C20.1481 12.1533 20.5 13.3133 20.5 14.5C20.5 16.0913 19.8679 17.6174 18.7426 18.7426C17.6174 19.8678 16.0913 20.5 14.5 20.5ZM14.5 11.5C13.9066 11.5 13.3266 11.6759 12.8333 12.0056C12.3399 12.3352 11.9554 12.8037 11.7284 13.3519C11.5013 13.9001 11.4419 14.5033 11.5576 15.0853C11.6734 15.6672 11.9591 16.2018 12.3787 16.6213C12.7982 17.0409 13.3328 17.3266 13.9147 17.4423C14.4966 17.5581 15.0999 17.4987 15.648 17.2716C16.1962 17.0445 16.6648 16.66 16.9944 16.1667C17.3241 15.6733 17.5 15.0933 17.5 14.5C17.5013 14.1052 17.4247 13.714 17.2745 13.3489C17.1243 12.9837 16.9036 12.6518 16.6248 12.3722C16.3461 12.0926 16.015 11.8707 15.6504 11.7193C15.2857 11.5679 14.8948 11.49 14.5 11.49V11.5Z"
                    fill="gray.200"
                />
            </chakra.svg>
        </Flex>
    )
);

FallbackImage.displayName = "FallbackImage";
