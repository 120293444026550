export enum ModalTypes {
    login = "login",
    contact = "contact",
    signOut = "signOut",
    sizeGuide = "sizeGuide",
    createAccount = "createAccount",
    forgotPassword = "forgotPassword",
    thankYouForSignUp = "thankYouForSignUp",
    contactForm = "contactForm",
    contactFormSubmitted = "contactFormSubmitted",
    shippingDetails = "shippingDetails",
}
