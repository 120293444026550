const scale = {
    "0.2": "1px",
    "1": "5px",
    "2": "10px",
    "2.8": "14px",
    "3": "15px",
    "4": "20px",
    "5": "25px",
    "6": "30px",
    "6.8": "34px",
    "7": "35px",
    "8": "40px",
    "8.4": "42px",
    "9": "45px",
    "9.6": "48px",
    "10": "50px",
    "10.4": "52px",
    "12": "60px",
    "14": "70px",
    "14.4": "72px",
    "16": "80px",
    "18": "90px",
    "19": "95px",
    "20": "100px",
    "24": "120px",
    "28": "140px",
    "30": "150px",
    "31.4": "157px",
    "32": "160px",
    "32.4": "162px",
    "36": "180px",
    "39": "195px",
    "40": "200px",
    "0.5": "2.5px",
    "1.5": "7.5px",
    "2.5": "12.5px",
    "3.5": "17.5px",
    "104": "520px",
    "60": "300px",
    "69": "345px",
    "75": "375px",
    "92": "460px",
    "113": "565px",
    "51": "255px",
    layoutMargin: {
        mobile: "15px",
        tab: "20px",
        desktop: "30px",
    },
};

export default scale;
