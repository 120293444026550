import * as Yup from "yup";
import { countryCode, onlyLetters, onlyNumbers } from "../regex";
import { checkValidEmailDomain } from "../user/logInSchema";
import { string } from "yup";
import { getOrderNumberFormatRegex } from "@config/site/site-config";

export type ContactFormErrorHeaders = {
    email?: string;
    enterPhone?: string;
    phoneRequirements?: string;
    orderNumberRequirements?: string;
    firstName?: string;
    lastName?: string;
    requiredField: string;
    maxNameCharacters?: string;
    maxEmailCharacters?: string;
    maxPhoneCharacters?: string;
    maxCommentCharacters?: string;
    countryCode?: string;
};

export function getContactFormValidationSchema(headers: ContactFormErrorHeaders) {
    return Yup.object().shape({
        firstName: string()
            .matches(onlyLetters, headers.firstName)
            .max(25, headers.maxNameCharacters)
            .required(headers.requiredField),
        lastName: string()
            .matches(onlyLetters, headers.lastName)
            .max(25, headers.maxNameCharacters)
            .required(headers.requiredField),
        email: Yup.string()
            .required(headers.requiredField)
            .email(headers.email)
            .max(35, headers.maxEmailCharacters)
            .concat(checkValidEmailDomain(headers.email)),
        phone: Yup.string()
            .matches(onlyNumbers, headers.phoneRequirements)
            .max(10, headers.maxPhoneCharacters)
            .required(headers.requiredField),
        orderNumber: Yup.string().matches(
            getOrderNumberFormatRegex,
            headers.orderNumberRequirements
        ),
        myQuestion: Yup.string().required(headers.requiredField).notOneOf(["Select A Topic"]),
        comment: Yup.string()
            .max(1000, headers.maxCommentCharacters)
            .required(headers.requiredField),
        countryCode: Yup.string()
            .matches(countryCode, headers.countryCode)
            .required(headers.requiredField),
    });
}
