import type { LinkProps as _LinkProps, SystemProps } from "@chakra-ui/react";
import { chakra, forwardRef, Link as _Link } from "@chakra-ui/react";

export interface LinkProps extends _LinkProps {
    /**
     * If added, the link will show an icon before the link text.
     * @type React.ReactElement
     */
    leftIcon?: React.ReactElement;
    /**
     * If added, the link will show an icon after the link text.
     * @type React.ReactElement
     */
    rightIcon?: React.ReactElement;
    /**
     * The space between the link icon and text.
     * @type SystemProps["marginRight"]
     */
    iconSpacing?: SystemProps["marginRight"];
    text?: string;
}

export const Link = forwardRef<LinkProps, "a">(
    ({ href, children, leftIcon, rightIcon, iconSpacing = "0.5rem", ...props }, ref) => (
        <_Link ref={ref} href={href} {...props}>
            {leftIcon && (
                <>
                    {leftIcon}
                    <chakra.span marginEnd={iconSpacing} />
                </>
            )}
            {children}
            {rightIcon && (
                <>
                    <chakra.span marginStart={iconSpacing} />
                    {rightIcon}
                </>
            )}
        </_Link>
    )
);
