const CommonCMS = {
    global: "global",
    page: "page",
    total: "uiText.total",
    subtotal: "uiText.subtotal",
    discount: "uiText.discount",
    shipping: "uiText.shipping",
    homeButtonAria: "aria.homeButton",
    closeModal: "aria.closeModal",
    skipLinkText: "skipLinkText",
    skipLinkTarget: "skipLinkTarget",
    navigationMenu: {
        ariaButtonOpenMenu: "aria.openNavMenu",
        ariaButtonCloseMenu: "aria.closenNavMenu",
    },
    salesTax: {
        title: "uiText.salesTax.title",
        info: "uiText.salesTax.info",
    },
    PDP: {
        itemNumber: "uiText.PDP.itemNumber",
        featuresAndBenefits: "uiText.PDP.featuresAndBenefits",
        deliveryAndReturnsHeader: "uiText.PDP.deliveryAndReturnsHeader",
        deliveryAndReturns: "uiText.PDP.deliveryAndReturns",
    },
    size: {
        title: "uiText.size.title",
        select: "uiText.size.select",
        labelEU: "uiText.sizeLabelEU",
        labelUS: "uiText.sizeLabelUS",
        labelUK: "uiText.sizeLabelUK",
        labelCM: "uiText.sizeLabelCM",
        labelKR: "uiText.sizeLabelKR",
        labelCA: "uiText.sizeLabelCA",
        labelJP: "uiText.sizeLabelJP",
        labelCN: "uiText.sizeLabelCN",
        ariaEU: "aria.sizeLabelUK",
        ariaUS: "aria.sizeLabelUS",
        ariaUK: "aria.sizeLabelUK",
        ariaCM: "aria.sizeLabelCM",
        ariaKR: "aria.sizeLabelKR",
        ariaCA: "aria.sizeLabelCA",
        ariaJP: "aria.sizeLabelJP",
        ariaCN: "aria.sizeLabelCN",
    },
    gender: {
        title: "uiText.gender.title",
        male: "uiText.gender.male",
        female: "uiText.gender.female",
        neutral: "uiText.gender.neutral",
    },
    promoCode: {
        title: "uiText.promoCode.title",
        apply: "uiText.promoCode.button.apply",
        error: "uiText.promoCode.error",
        unusable: "uiText.promoCode.nolongervalid",
        remove: "uiText.promoCode.remove",
        removeAria: "uiText.promoCode.removeAria",
        applyAria: "uiText.promoCode.applyAria",
    },
    product: "uiText.product",
    colour: "uiText.colour",
    colourIs: "uiText.colourIs",
    inSize: "uiText.inSize",
    quantity: {
        title: "uiText.quantity.title",
        select: "uiText.quantity.select",
    },
    openCountrySelector: "ariaLabel.openCountrySelector",
    countrySelector: "ariaLabel.countrySelector",
    countrySelectorItem: "ariaLabel.countrySelectorItem",
    youMightAlsoLikeMC: "PDP.youMightAlsoLike",
    recentlyViewedMC: "PDP.recentlyViewed",
    backText: "uiText.backText",
    addedToBagTitle: "uiText.addedToBag.title",
    email: "uiText.email",
    subscribeButton: "uiText.subscribeButton",
    password: "uiText.password",
    firstName: "uiText.firstName",
    lastName: "uiText.lastName",
    requiredLabel: "uiText.requiredLabel",
    country: "checkout.step1.formCountry.title",
    phone: "checkout.step1.formPhone.title",
    passwordRules: "uiText.passwordRules",
    resetPassword: "uitext.resetPassword",
    resetPassDesc: "text.resetPasswordDescription",
    resetPasswordSentText: "uiText.ResetPasswordSuccess",
    resetEmailSentThanks: "uiText.ResetPasswordThanks",
    sendResetPasswordButton: "uiText.sendResetPasswordButton",
    backToLogin: "uiText.backToLogin",
    resetPasswordLink: "linkRoute.resetPassword",
    resetEmailSentSuccess: "text.resetEmailSentSuccessDescription",
    profile: { overview: "uiText.profile.overview" },
    button: {
        checkout: "uiText.button.checkout",
        shoppingBag: "uiText.button.shoppingBag",
        logIn: "uiText.button.logIn",
        createAccount: "uiText.button.createAccount",
        logOut: "uiText.button.logout",
        allFilters: "uiText.button.allFilters",
        aria: {
            expand: "aria.button.expand",
        },
    },
    logInTitle: "uiText.logIn.title",
    createAccountTitle: "uiText.createAccount.title",
    error: {
        notAvailableShort: "uiText.error.notAvailable.short",
        notAvailableLong: "uiText.error.notAvailable.long",
        email: "uiText.error.email",
        firstName: "checkout.step2.formFirstName.error",
        lastName: "checkout.step2.formLastName.error",
        emailOrPasswordError: "uiText.error.emailOrPassword",
        requiredFieldError: "uiText.error.requiredField",
        general: {
            title: "genericError.title",
            description: "genericError.description",
        },
        passwordRules: "uiText.error.passwordRules",
        maxCharacters: "uiText.error.maxCharacters",
        regionInCountry: "error.regionInCountry",
        onlyLocalShipping: "error.onlyLocalShipping",
        headlineFourOhFour: "uiText.error.404.headline",
        textFourOhFour: "uiText.error.404.text",
        headlineFiveHundred: "uiText.error.500.headline",
        textFiveHundred: "uiText.error.500.text",
        phone: "checkout.step1.formPhone.error",
        orderNumber: "uiText.error.orderNumber",
        countryCode: "uiText.error.countryCode",
    },
    free: "uiText.free",
    cookieRenew: "uiText.cookieRenew",
    myAccount: "uiText.myAccount",
    help: "uiText.needHelp",
    ECCOMember: "uiText.ECCOMember",
    loggedInAs: "uiText.loggedInAs",
    notLoggedIn: "uiText.notLoggedIn",
    searchBarPlaceholder: "uiText.searchBarPlaceholder",
    searchBarAriaIntro: "aria.searchBarAriaIntro",
    searchBarRecentSearches: "uiText.searchBarRecentSearches",
    searchBarPopularSearches: "uiText.searchBarPopularSearches",
    searchbarPlaceholder: "uiText.searchBarPlaceholder",
    searchbarRecentSearches: "uiText.searchBarRecentSearches",
    searchbarPopularSearches: "uiText.searchBarPopularSearches",
    thankYouForSign: "uiText.thankYouForSign",
    edit: "uiText.edit",
    logInInvitation: "uiText.logInInvitation",
    loggedOut: "uiText.loggedOut",
    logBackButton: "uiText.logBackButton",
    usps: ["usps.first", "usps.second", "usps.third"],
    followUs: "uiText.followUs",
    backToTop: "uiText.backToTop",
    shoes: "uiText.shoes",
    other: "uiText.other",
    remove: "uiText.remove",
    charactersLeft: "uiText.charactersLeft",
    generic: {
        readMore: "generic.readMore",
        readLess: "generic.readLess",
        readMoreAria: "generic.readMoreAria",
        readLessAria: "generic.readLessAria",
    },
    slide: {
        nextSlide: "uiText.nextSlide.text",
        previousSlide: "uiText.previousSlide.text",
    },
    orderNumber: "orderConfirmation.orderNumber",
    contactForm: {
        title: "uiText.contactForm.title",
        description: "uiText.contactForm.description",
        myQuestion: "uiText.contactForm.myQuestion",
        comment: "uiText.contactForm.comment",
        submitLabel: "uiText.contactForm.submitLabel",
        commentCharacters: "uiText.charactersLeft",
        messageSent: "uiText.contactForm.messageSent",
        thankYouContact: "uiText.contactForm.thankYouContact",
        placeholders: {
            email: "uiText.contactForm.placeholders.email",
            firstName: "uiText.contactForm.placeholders.firstName",
            lastName: "uiText.contactForm.placeholders.lastName",
            phone: "uiText.contactForm.placeholders.phone",
            myQuestion: "uiText.contactForm.placeholders.myQuestion",
            orderNumber: "uiText.contactForm.placeholders.orderNumber",
            comment: "uiText.contactForm.placeholders.comment",
        },
        options: {
            cancelOrder: "uiText.contactForm.myQuestionOptions.cancelChangeOrder",
            generalInfo: "uiText.contactForm.myQuestionOptions.generalInfo",
            laceRequest: "uiText.contactForm.myQuestionOptions.laceRequest",
            myAccount: "uiText.contactForm.myQuestionOptions.myAccount",
            orderStatus: "uiText.contactForm.myQuestionOptions.orderStatus",
            other: "uiText.contactForm.myQuestionOptions.other",
            productInfo: "uiText.contactForm.myQuestionOptions.productInfo",
            return: "uiText.contactForm.myQuestionOptions.return",
            exchange: "uiText.contactForm.myQuestionOptions.exchange",
            shoeCare: "uiText.contactForm.myQuestionOptions.shoeCare",
            warranty: "uiText.contactForm.myQuestionOptions.warranty",
            website: "uiText.contactForm.myQuestionOptions.website",
            refunds: "uiText.contactForm.myQuestionOptions.refunds",
            promo: "uiText.contactForm.myQuestionOptions.promo",
        },
    },
};

export default CommonCMS;
